.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tablink:hover {
	color: blue;
	cursor: pointer;
}

.hybrid {
	background-color: midnightblue !important;
}
.hoverlight:hover {
	background-color:cornsilk;
}
.pageheader {
	color: darkslategray;
	margin-bottom: 1em;
}
.pageheader:hover {
	color: lightslategray;
}
.pageheaderX {
	color: white;
	padding: 0.5em;
	background-image: url('img/CrystalBackground.jpg');
	margin-bottom: 1em;
}
.icglink {
	color: skyblue;
	cursor: pointer;
	font-style: italic;
	text-align: right;
	font-size: 0.65em;
	margin: 0;
}
.icglink:hover {
	color: gold;
}
@keyframes fadeout {
	from {opacity: 1;}
	to {opacity: 0;}
}
.fader {
	animation: fadeout 2s forwards;
	animation-delay: 3s;
}
.blush:hover {
	background-color: mintcream;
}
.snow:hover {
	background-color: snow;
}
.namebar {
	background-color: midnightblue;
	color: orange;
	font-weight: bold;
	font-size: 0.75em;
	text-align: right;
	padding-right: 1em;
}
.disa[disabled] {
	pointer-events: none;
}
.formpanel {
	padding: 1em 0 1em 0;
}
.formpanel:hover {
	background-color: mintcream;
}


.react-datepicker__time-container, .react-datepicker__time-list {
	width: 95px !important;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 105px !important;
}
.recat-datepicker__input-container {
	width: 50% !important;
}
.timepicker {
	border: 0;
	color: darkorange;
	cursor: pointer;
	font-size: 0.8rem;
	font-weight: bold;
}

.timeme {
	padding: 1em;
	border: 1px solid white;
	border-radius: 4px;
}
.timeme:hover {
	border: 1px solid orange;
	background-color: snow;
}
.popover {
	max-width: 1000px !important;
}
.underliner:hover {
	text-decoration: underline;
}
