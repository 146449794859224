body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: left;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.tablink:hover {
	color: blue;
	cursor: pointer;
}

.hybrid {
	background-color: midnightblue !important;
}
.hoverlight:hover {
	background-color:cornsilk;
}
.pageheader {
	color: darkslategray;
	margin-bottom: 1em;
}
.pageheader:hover {
	color: lightslategray;
}
.pageheaderX {
	color: white;
	padding: 0.5em;
	background-image: url(/static/media/CrystalBackground.3dab4732.jpg);
	margin-bottom: 1em;
}
.icglink {
	color: skyblue;
	cursor: pointer;
	font-style: italic;
	text-align: right;
	font-size: 0.65em;
	margin: 0;
}
.icglink:hover {
	color: gold;
}
@-webkit-keyframes fadeout {
	from {opacity: 1;}
	to {opacity: 0;}
}
@keyframes fadeout {
	from {opacity: 1;}
	to {opacity: 0;}
}
.fader {
	-webkit-animation: fadeout 2s forwards;
	        animation: fadeout 2s forwards;
	-webkit-animation-delay: 3s;
	        animation-delay: 3s;
}
.blush:hover {
	background-color: mintcream;
}
.snow:hover {
	background-color: snow;
}
.namebar {
	background-color: midnightblue;
	color: orange;
	font-weight: bold;
	font-size: 0.75em;
	text-align: right;
	padding-right: 1em;
}
.disa[disabled] {
	pointer-events: none;
}
.formpanel {
	padding: 1em 0 1em 0;
}
.formpanel:hover {
	background-color: mintcream;
}


.react-datepicker__time-container, .react-datepicker__time-list {
	width: 95px !important;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 105px !important;
}
.recat-datepicker__input-container {
	width: 50% !important;
}
.timepicker {
	border: 0;
	color: darkorange;
	cursor: pointer;
	font-size: 0.8rem;
	font-weight: bold;
}

.timeme {
	padding: 1em;
	border: 1px solid white;
	border-radius: 4px;
}
.timeme:hover {
	border: 1px solid orange;
	background-color: snow;
}
.popover {
	max-width: 1000px !important;
}
.underliner:hover {
	text-decoration: underline;
}

